import React from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/mycss.css";
import styled from "styled-components";
import background from "../assets/img/proyecto/derecho civil/difamacion/1800x800/3.jpg";
import { useTranslation } from "react-i18next";

export default function Difamacion() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <DivContainer>
        <div className="main-header">
          <br />
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-12">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <NavLink to="/civil">{t("body.texto10")}</NavLink>
                        </li>
                        <li className="breadcrumb-item active">
                          {t("civil.texto3")}
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                {/* /.container-fluid */}
              </section>
              <div className="card card-widget">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators"></ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        className="d-block w-100"
                        src={background}
                        alt="First slide"
                      />
                    </div>
                  </div>
                </div>

                {/* /.card-header */}
                <div className="row">
                  <div className="col-md-12">
                    {/* Box Comment */}
                    {/* /.card-header */}
                    <div className="card-body">
                      <h4>{t("difamacion.texto1")}</h4>
                      <h4>{t("difamacion.texto2")}</h4>
                      <ol>
                        <li> {t("difamacion.texto3")}</li>
                        <li> {t("difamacion.texto4")}</li>
                      </ol>
                      <h4>{t("difamacion.texto5")}</h4>
                      <ol>
                        <li> {t("difamacion.texto6")}</li>
                        <ol>
                          <li> {t("difamacion.texto7")}</li>
                          <li> {t("difamacion.texto8")}</li>
                        </ol>
                        <li> {t("difamacion.texto9")}</li>
                        <ol>
                          <li> {t("difamacion.texto10")}</li>
                          <li> {t("difamacion.texto11")}</li>
                        </ol>
                        <li> {t("difamacion.texto12")}</li>
                        <ol>
                          <li> {t("difamacion.texto13")}</li>
                          <li> {t("difamacion.texto14")}</li>
                        </ol>
                      </ol>
                      <h5>{t("difamacion.texto15")}</h5>
                    </div>
                    {/* /.card-footer */}
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
        </div>

        <a
          id="back-to-top"
          href="#"
          className="btn btn-primary back-to-top"
          role="button"
          aria-label="Scroll to top"
        >
          <i className="fas fa-chevron-up"></i>
        </a>
      </DivContainer>
    </>
  );
}

const DivContainer = styled.div`
  padding: 0rem;
  background-color: #343a40;
  h3 {
    padding: 0.6rem;
  }
`;

const NavContainer = styled.nav`
  h2 {
    color: #d4ac0d;
    font-weight: 400;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }

  padding: 0.9rem;
  background-color: #333;
  align-items: center;
  justify-content: space-between;
`;
