import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/proyecto/logo/logo.png";

import familia1 from "../assets/img/proyecto/derecho a la familia/familia1.jpg";
import familia2 from "../assets/img/proyecto/derecho a la familia/familia2.jpg";
import laboral1 from "../assets/img/proyecto/derecho laboral/laboral1.jpg";
import laboral2 from "../assets/img/proyecto/derecho laboral/laboral2.jpg";
import empresarial1 from "../assets/img/proyecto/abogado empresarial/empresarial1.jpg";
import empresarial2 from "../assets/img/proyecto/abogado empresarial/empresarial2.jpg";
import extranjeria1 from "../assets/img/proyecto/abogado de extranjeria/extranjeria1.jpg";
import extranjeria2 from "../assets/img/proyecto/abogado de extranjeria/extranjeria2.jpg";
import civil1 from "../assets/img/proyecto/derecho civil/civil1.jpg";
import civil2 from "../assets/img/proyecto/derecho civil/civil2.jpg";
import background from "../assets/img/home/home0_1800x800.jpg";
import pexelsmikhailnilov8296981 from "../assets/img/proyecto/pexels-mikhail-nilov-8296981.jpg";
import home from "../assets/img/proyecto/body/body6.jpg";
import administrativo from "../assets/img/proyecto/administrativo.png";
import gestion1 from "../assets/img/proyecto/gestion empresarial/gestion1.jpg";
import notario1 from "../assets/img/proyecto/notario/notario1.jpg";
import internacional1 from "../assets/img/proyecto/abogado de derecho internacional/internacional1.jpg";
import penal1 from "../assets/img/proyecto/abogado penal/penal1.jpg";
import penal2 from "../assets/img/proyecto/abogado penal/penal2.jpg";

import styled from "styled-components";

import { useTranslation } from "react-i18next";

export default function Body() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <BodyContainer>
      <body>
        <div
          className="col-sm-12 col-md-12 fl-page-logo-wrap"
          id="yui_3_18_1_1_1719501602214_1157"
        >
          <div
            id="fl-main-content"
            className="fl-page-content"
            itemProp="mainContentOfPage"
            role="main"
          >
            <div
              className="fl-content-full container"
              id="yui_3_18_1_1_1719501602214_1533"
            >
              <div className="row" id="yui_3_18_1_1_1719501602214_1532">
                <div
                  className="fl-content col-md-12"
                  id="yui_3_18_1_1_1719501602214_1531"
                >
                  <div
                    className="fl-post post-2 page type-page status-publish hentry"
                    id="fl-post-2"
                    itemScope="itemscope"
                    itemType="https://schema.org/CreativeWork"
                  >
                    <div
                      className="fl-post-content clearfix"
                      itemProp="text"
                      id="yui_3_18_1_1_1719501602214_1530"
                    >
                      <div
                        className="fl-builder-content fl-builder-content-2 fl-builder-content-primary fl-builder-global-templates-locked"
                        data-post-id={2}
                        id="yui_3_18_1_1_1719501602214_1529"
                      >
                        <div
                          id="carouselExampleIndicators"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators"></ol>
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img
                                className="d-block w-100"
                                src={home}
                                alt="First slide"
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="fl-row fl-row-full-width fl-row-bg-photo fl-node-6092c7848ba9d fl-row-default-height fl-row-align-center fl-row-bg-overlay fl-row-bg-fixed"
                          data-node="6092c7848ba9d"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div
                                className="fl-col-group fl-node-6092cabcec94c fl-col-group-equal-height fl-col-group-align-center"
                                data-node="6092cabcec94c"
                              >
                                <div
                                  className="fl-col fl-node-6092cabceca4d fl-col-small"
                                  data-node="6092cabceca4d"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-rich-text fl-node-6092d40dc8ecf"
                                      data-node="6092d40dc8ecf"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="fl-rich-text">
                                          <p>{t("body.texto1")}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-rich-text fl-node-6092cb0a4abd3"
                                      data-node="6092cb0a4abd3"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="fl-rich-text">
                                          <p>{t("body.texto2")}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="fl-col fl-node-6092cabceca50 fl-visible-desktop fl-visible-large fl-visible-medium"
                                  data-node="6092cabceca50"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-slideshow fl-node-609423f233daa fl-visible-desktop fl-visible-large fl-visible-medium fl-animation fl-fade-right fl-animated"
                                      data-node="609423f233daa"
                                      data-animation-delay={0}
                                      data-animation-duration={1}
                                      style={{ animationDuration: "1s" }}
                                    >
                                      <div
                                        id="carouselExampleIndicators"
                                        className="carousel slide"
                                        data-ride="carousel"
                                      >
                                        <ol className="carousel-indicators"></ol>
                                        <div className="carousel-inner">
                                          <div className="carousel-item active">
                                            <img
                                              className="d-block w-100"
                                              src={background}
                                              alt="First slide"
                                            />
                                            <div className="carousel-caption d-none d-md-block"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="fl-row fl-row-full-width fl-row-bg-none fl-node-6092cb08048ee fl-row-default-height fl-row-align-center"
                          data-node="6092cb08048ee"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div
                                className="fl-col-group fl-node-6092cb0809677"
                                data-node="6092cb0809677"
                              ></div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="fl-row fl-row-full-width fl-row-bg-none fl-node-6094070f68f85 fl-row-default-height fl-row-align-center"
                          data-node="6094070f68f85"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div
                                className="fl-col-group fl-node-60940758b1b45"
                                data-node="60940758b1b45"
                              >
                                <div
                                  className="fl-col fl-node-60940758b1c0d"
                                  data-node="60940758b1c0d"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-pp-hover-cards-2 fl-node-60940758b1ac0 fl-visible-desktop fl-visible-large fl-visible-medium"
                                      data-node="60940758b1ac0"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-hover-card-wrap pp-clearfix">
                                          <div
                                            className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/familia"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={familia2}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                          <div
                                            className="pp-hover-card pp-hover-card-1 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/laboral"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={laboral1}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                          <div
                                            className="pp-hover-card pp-hover-card-2 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/penal"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={penal2}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                          <div
                                            className="pp-hover-card pp-hover-card-3 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/empresarial"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={empresarial2}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="fl-col-group fl-node-6094073c27040"
                                data-node="6094073c27040"
                              >
                                <div
                                  className="fl-col fl-node-6094073c2716d fl-col-small"
                                  data-node="6094073c2716d"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-pp-hover-cards-2 fl-node-60941cd1acd30 fl-visible-mobile"
                                      data-node="60941cd1acd30"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-hover-card-wrap pp-clearfix">
                                          <div
                                            className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/familia"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={familia1}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-pp-smart-button fl-node-60940fe77dcb1"
                                      data-node="60940fe77dcb1"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-button-wrap pp-button-width-full">
                                          <NavLink
                                            to="/familia"
                                            target="_self"
                                            className="pp-button"
                                            role="button"
                                            aria-label={t("body.texto5")}
                                          >
                                            <span className="pp-button-text">
                                              {t("body.texto5")}
                                            </span>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="fl-col fl-node-6094073c27170 fl-col-small"
                                  data-node="6094073c27170"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-pp-hover-cards-2 fl-node-60941c0b95ddc fl-visible-mobile"
                                      data-node="60941c0b95ddc"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-hover-card-wrap pp-clearfix">
                                          <div
                                            className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/laboral"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={laboral2}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-pp-smart-button fl-node-60940fea568ee"
                                      data-node="60940fea568ee"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-button-wrap pp-button-width-full">
                                          <NavLink
                                            to="/laboral"
                                            target="_self"
                                            className="pp-button"
                                            role="button"
                                            aria-label={t("body.texto6")}
                                          >
                                            <span className="pp-button-text">
                                              {t("body.texto6")}
                                            </span>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="fl-col fl-node-6094106250e49 fl-col-small"
                                  data-node="6094106250e49"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-pp-hover-cards-2 fl-node-60941c8040635 fl-visible-mobile"
                                      data-node="60941c8040635"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-hover-card-wrap pp-clearfix">
                                          <div
                                            className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/penal"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={penal1}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-pp-smart-button fl-node-6094106250e4b"
                                      data-node="6094106250e4b"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-button-wrap pp-button-width-full">
                                          <NavLink
                                            to="/penal"
                                            target="_self"
                                            className="pp-button"
                                            role="button"
                                            aria-label={t("body.texto7")}
                                          >
                                            <span className="pp-button-text">
                                              {t("body.texto7")}
                                            </span>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="fl-col fl-node-6094073c27172 fl-col-small"
                                  data-node="6094073c27172"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-pp-hover-cards-2 fl-node-60941c4861ebb fl-visible-mobile"
                                      data-node="60941c4861ebb"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-hover-card-wrap pp-clearfix">
                                          <div
                                            className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/empresarial"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={empresarial1}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-pp-smart-button fl-node-60940fed765ef"
                                      data-node="60940fed765ef"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-button-wrap pp-button-width-full">
                                          <NavLink
                                            to="/empresarial"
                                            target="_self"
                                            className="pp-button"
                                            role="button"
                                            aria-label="ABOGADO EMPRESARIAL"
                                          >
                                            <span className="pp-button-text">
                                              {t("body.texto8")}
                                            </span>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="fl-row fl-row-full-width fl-row-bg-photo fl-node-6094106250b3a fl-row-default-height fl-row-align-center fl-row-bg-overlay fl-row-bg-fixed"
                          data-node="6094106250b3a"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div
                                className="fl-col-group fl-node-6094106250e3c"
                                data-node="6094106250e3c"
                              >
                                <div
                                  className="fl-col fl-node-6094106250e3f"
                                  data-node="6094106250e3f"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-pp-hover-cards-2 fl-node-6094106250e40 fl-visible-desktop fl-visible-large fl-visible-medium"
                                      data-node="6094106250e40"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-hover-card-wrap pp-clearfix">
                                          <div
                                            className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/extranjeria"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={extranjeria2}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                          <div
                                            className="pp-hover-card pp-hover-card-1 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/civil"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={civil2}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                          <div
                                            className="pp-hover-card pp-hover-card-2 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/internacional"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={pexelsmikhailnilov8296981}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                          <div
                                            className="pp-hover-card pp-hover-card-3 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/gestor"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={extranjeria2}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="fl-col-group fl-node-6094106250e46"
                                data-node="6094106250e46"
                              >
                                <div
                                  className="fl-col fl-node-6094106250e4e fl-col-small"
                                  data-node="6094106250e4e"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-pp-hover-cards-2 fl-node-60941c836e362 fl-visible-mobile"
                                      data-node="60941c836e362"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-hover-card-wrap pp-clearfix">
                                          <div
                                            className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/extranjeria"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={extranjeria1}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-pp-smart-button fl-node-6094106250e4f"
                                      data-node="6094106250e4f"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-button-wrap pp-button-width-full">
                                          <NavLink
                                            to="/extranjeria"
                                            target="_self"
                                            className="pp-button"
                                            role="button"
                                            aria-label="ABOGADO DE EXTRANJERÍA"
                                          >
                                            <span className="pp-button-text">
                                              {t("body.texto9")}
                                            </span>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="fl-col fl-node-6094106250e50 fl-col-small"
                                  data-node="6094106250e50"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-pp-hover-cards-2 fl-node-60941c8742335 fl-visible-mobile"
                                      data-node="60941c8742335"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-hover-card-wrap pp-clearfix">
                                          <div
                                            className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/civil"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={civil1}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-pp-smart-button fl-node-6094106250e52"
                                      data-node="6094106250e52"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-button-wrap pp-button-width-full">
                                          <NavLink
                                            to="/civil"
                                            target="_self"
                                            className="pp-button"
                                            role="button"
                                            aria-label="ABOGADO CIVIL"
                                          >
                                            <span className="pp-button-text">
                                              {t("body.texto10")}
                                            </span>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="fl-col fl-node-6094106250e5c fl-col-small"
                                  data-node="6094106250e5c"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-pp-hover-cards-2 fl-node-60941c77b8b7b fl-visible-mobile"
                                      data-node="60941c77b8b7b"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-hover-card-wrap pp-clearfix">
                                          <div
                                            className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/internacional"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={internacional1}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-pp-smart-button fl-node-6094106250e5d"
                                      data-node="6094106250e5d"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-button-wrap pp-button-width-full">
                                          <NavLink
                                            to="/internacional"
                                            target="_self"
                                            className="pp-button"
                                            role="button"
                                            aria-label="ABOGADO DE DERECHO INTERNACIONAL"
                                          >
                                            <span className="pp-button-text">
                                              {t("body.texto11")}
                                            </span>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="fl-col fl-node-609413c01a8d9 fl-col-small"
                                  data-node="609413c01a8d9"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-pp-hover-cards-2 fl-node-60941cf989e7a fl-visible-mobile"
                                      data-node="60941cf989e7a"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-hover-card-wrap pp-clearfix">
                                          <div
                                            className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/gestor"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={gestion1}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-pp-smart-button fl-node-609413c01a8da"
                                      data-node="609413c01a8da"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-button-wrap pp-button-width-full">
                                          <NavLink
                                            to="/gestor"
                                            target="_self"
                                            className="pp-button"
                                            role="button"
                                            aria-label="GESTOR EMPRESARIAL"
                                          >
                                            <span className="pp-button-text">
                                              {t("body.texto12")}
                                            </span>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="fl-row fl-row-full-width fl-row-bg-photo fl-node-4jv39xtmqelc fl-row-default-height fl-row-align-center fl-row-bg-overlay fl-row-bg-fixed"
                          data-node="4jv39xtmqelc"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div
                                className="fl-col-group fl-node-6tej172mfg0a"
                                data-node="6tej172mfg0a"
                              >
                                <div
                                  className="fl-col fl-node-bqkm7t20j9d8"
                                  data-node="bqkm7t20j9d8"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-pp-hover-cards-2 fl-node-69l3vmhpb5kg fl-visible-desktop fl-visible-large fl-visible-medium"
                                      data-node="69l3vmhpb5kg"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-hover-card-wrap pp-clearfix">
                                          <div
                                            className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/notario"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={
                                                  administrativo
                                                }
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="fl-col-group fl-node-ld47sbpm5ucx"
                                data-node="ld47sbpm5ucx"
                              >
                                <div
                                  className="fl-col fl-node-o4xwtd7avl0z fl-col-small"
                                  data-node="o4xwtd7avl0z"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-pp-hover-cards-2 fl-node-jwzqtg27x90f fl-visible-mobile"
                                      data-node="jwzqtg27x90f"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-hover-card-wrap pp-clearfix">
                                          <div
                                            className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                            onclick
                                            tabIndex={0}
                                          >
                                            <NavLink
                                              to="/notario"
                                              className="pp-more-link-container"
                                              target="_self"
                                            >
                                              <img
                                                decoding="async"
                                                src={notario1}
                                                className="pp-hover-card-image"
                                                alt
                                              />
                                              <div className="pp-hover-card-inner">
                                                <div className="pp-hover-card-inner-wrap">
                                                  <div className="pp-hover-card-content">
                                                    <div className="pp-hover-card-title-wrap">
                                                      <h2 className="pp-hover-card-title" />
                                                    </div>
                                                    <div className="pp-hover-card-description">
                                                      <div className="pp-hover-card-description-inner">
                                                        <p>
                                                          {t("body.texto4")}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pp-hover-card-overlay" />
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-pp-smart-button fl-node-al142z65gfen"
                                      data-node="al142z65gfen"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-button-wrap pp-button-width-full">
                                          <NavLink
                                            to="/notario"
                                            target="_self"
                                            className="pp-button"
                                            role="button"
                                            aria-label="NOTARIO"
                                          >
                                            <span className="pp-button-text">
                                              {t("body.texto13")}
                                            </span>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a
          id="back-to-top"
          href="#"
          className="btn btn-primary back-to-top"
          role="button"
          aria-label="Scroll to top"
        >
          <i className="fas fa-chevron-up"></i>
        </a>
      </body>

      </BodyContainer>
    </>
  );
}

const BodyContainer = styled.body`
  background-color: #343a40;
  h1 {
    color: #f3eded;
  }
  h3 {
    color: #d4ac0d;
    font-weight: 400;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }
`;

const NavContainer = styled.nav`
  h2 {
    color: #d4ac0d;
    font-weight: 400;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }

  padding: 0.9rem;
  background-color: #333;
  align-items: center;
  justify-content: space-between;
`;
