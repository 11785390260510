import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
import global_fr from "./translations/fr/global.json";
import global_hi from "./translations/hi/global.json";
import global_ar from "./translations/ar/global.json";
import global_pt from "./translations/pt/global.json";
import global_ru from "./translations/ru/global.json";
import global_ma from "./translations/ma/global.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "es",
  resources:{
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
    fr: {
      global: global_fr,
    },
    hi: {
      global: global_hi,
    },
    ar: {
      global: global_ar,
    },
    pt: {
      global: global_pt,
    },
    ru: {
      global: global_ru,
    },
    ma: {
      global: global_ma,
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
