import React from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/mycss.css";
import styled from "styled-components";

import civil1_2400x1600 from "../assets/img/proyecto/derecho civil/civil1.jpg";

import { useTranslation } from "react-i18next";

export default function Civil() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <DivContainer>
        <div className="main-header">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/">{t("navbar.page1")}</NavLink>
                    </li>
                    <li className="breadcrumb-item active">
                      {t("body.texto10")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators"></ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  className="d-block w-100"
                  src={civil1_2400x1600}
                  alt="First slide"
                />
                <div className="carousel-caption d-none d-md-block"></div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>
          <br />

          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div>
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-12">
                    <NavLink to="/derechoautor">
                      <div className="info-box colordiv">
                        <span className="info-box-icon">
                          <i className="fas fa-arrow-circle-right" />
                        </span>
                        <div className="info-box-content">
                          <span className="info-box-text colortext">
                            {t("civil.texto1")}
                          </span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <span className="progress-description colortext"></span>
                        </div>
                        {/* /.info-box-content */}
                      </div>
                    </NavLink>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <NavLink to="/difamacion">
                      <div className="info-box colordiv">
                        <span className="info-box-icon">
                          <i className="fas fa-arrow-circle-right" />
                        </span>
                        <div className="info-box-content">
                          <span className="info-box-text colortext">
                            {t("civil.texto3")}
                          </span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <span className="progress-description colortext"></span>
                        </div>
                        {/* /.info-box-content */}
                      </div>
                    </NavLink>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <NavLink to="/negligenciamedica">
                      <div className="info-box colordiv">
                        <span className="info-box-icon">
                          <i className="fas fa-arrow-circle-right" />
                        </span>
                        <div className="info-box-content">
                          <span className="info-box-text colortext">
                            {t("civil.texto4")}
                          </span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <span className="progress-description colortext"></span>
                        </div>
                        {/* /.info-box-content */}
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a
          id="back-to-top"
          href="#"
          className="btn btn-primary back-to-top"
          role="button"
          aria-label="Scroll to top"
        >
          <i className="fas fa-chevron-up"></i>
        </a>
      </DivContainer>
    </>
  );
}

const DivContainer = styled.div`
  padding: 0.5rem;
  .color-fuente {
    background-color: #222;
  }
  .colordiv {
    background-color: #001952;
  }
  .colortext {
    color: white;
  }
`;
