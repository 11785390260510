import React from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/mycss.css";
import styled from "styled-components";
import background from "../assets/img/proyecto/derecho a la familia/divorcio/1800x800/1.jpg";
import { useTranslation } from "react-i18next";

export default function DivorcioEspana() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <DivContainer>
        <div className="main-header">
          <br />
          <div className="row">
            <div className="col-md-2">
            </div>
            <div className="col-md-8">
            <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <NavLink to="/familia">{t("body.texto5")}</NavLink>
                        </li>
                        <li className="breadcrumb-item active">
                          {t("familia.texto5")}
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                {/* /.container-fluid */}
              </section>
              <div className="card card-widget">

              <div
                          id="carouselExampleIndicators"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators"></ol>
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img
                                className="d-block w-100"
                                src={background}
                                alt="First slide"
                              />
                            </div>
                          </div>
                        </div>
                {/* /.card-header */}
                <div className="row">
                  <div className="col-md-12">
                    {/* Box Comment */}
                      {/* /.card-header */}
                      <div className="card-body">
                      <h5>{t("divorcioespana.texto1")}</h5>
                      <h4>{t("divorcioespana.texto2")}</h4>
                        <h4>{t("divorcioespana.texto3")}</h4>
                        <ol>
                          <li> {t("divorcioespana.texto4")}</li>
                          <li> {t("divorcioespana.texto5")}</li>
                        </ol>
                        <h4>{t("divorcioespana.texto6")}</h4>
                        <ol>
                          <li> {t("divorcioespana.texto7")}</li>
                          <ol>
                            <li>
                              {" "}{t("divorcioespana.texto8")}
                            </li>
                            <li>
                              {" "}{t("divorcioespana.texto9")}
                            </li>
                            <li>
                              {" "}{t("divorcioespana.texto10")}
                            </li>
                            <li>
                              {" "}{t("divorcioespana.texto10")}
                            </li>
                          </ol>
                          <li> {t("divorcioespana.texto12")}</li>
                          <ol>
                            <li>
                              {" "}{t("divorcioespana.texto13")}
                            </li>
                            <li>
                              {" "}{t("divorcioespana.texto14")}
                            </li>
                            <li>
                              {" "}{t("divorcioespana.texto15")}
                            </li>
                            <li>
                              {" "}{t("divorcioespana.texto16")}
                            </li>
                            <li>
                              {" "}{t("divorcioespana.texto17")}
                            </li>
                          </ol>
                        </ol>
                        <h5>{t("divorcioespana.texto18")}</h5>
                        <h5>{t("divorcioespana.texto19")}</h5>
                      </div>
                      {/* /.card-footer */}
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
        </div>

        <a
            id="back-to-top"
            href="#"
            className="btn btn-primary back-to-top"
            role="button"
            aria-label="Scroll to top"
          >
            <i className="fas fa-chevron-up"></i>
          </a>
      </DivContainer>
    </>
  );
}

const DivContainer = styled.div`
  padding: 0rem;
  background-color: #343a40;
  h3 {
    padding: 0.6rem;
  }
`;

const NavContainer = styled.nav`
  h2 {
    color: #d4ac0d;
    font-weight: 400;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }

  padding: 0.9rem;
  background-color: #333;
  align-items: center;
  justify-content: space-between;
`;
